@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'vendor/sweetalert.theme';

html {
	font-size: 16px;
	scrollbar-color: #0075c9;
	font-family: 'Plus Jakarta Sans', sans-serif;
}

::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

::-webkit-scrollbar-track {
	background-color: transparent;
	border-radius: 100px;
}

::-webkit-scrollbar-thumb {
	background-color: #ababab98;
	border-radius: 100px;
	opacity: 0.5;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #c9c9c9;
	border-radius: 100px;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
	display: none;
}

// @media (min-width: 420px) {
// 	html {
// 		font-size: 14px;
// 	}
// }

input:valid {
	border-color: #d1d5db;
}

input:invalid {
	border-color: rgba(247, 32, 32, 0.3);
	border-width: 1.5px;
}

.has-error {
	border-color: rgba(247, 32, 32, 0.3) !important;
	border-width: 1.5px;
}

.fc .fc-toolbar-title {
	font-size: 1.25rem !important;
	margin: 0;
	font-weight: 500;
	padding: 20px 0 0px 20px;
}

.fc .fc-button {
	background-color: #0075c9 !important;
	border-color: #3391d4 !important;
	border-radius: 0.375rem !important;
	font-size: small !important;
	text-transform: capitalize !important;
}

.fc-day-today {
	background-color: #edf5f7 !important;
}

.fc-theme-standard td {
	border: 1px solid #e5e7eb !important;
}

.fc-day-other {
	background: #fafafb;
}

.fc .fc-button .fc-icon {
	font-size: 0.875rem !important;
}

a.fc-col-header-cell-cushion {
	font-size: 0.85em !important;
	line-height: 2.2rem !important;
	font-weight: 600 !important;
}

.fc .fc-daygrid-day-top {
	flex-direction: inherit !important;
	padding: 5px !important;
	font-size: 0.75em !important;
	color: #6b7280 !important;
}

.fc .fc-button-primary:disabled {
	background-color: #eeeeee !important;
	color: black !important;
	border-color: #eeeeee !important;
	font-size: 0.875rem !important;
	line-height: 1.25rem !important;
	text-transform: capitalize !important;
}

.fc .fc-list-event-dot {
	margin-right: 12px;
}
